
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import DataInfo from '@/components/reports-v2/components/elements/DataInfo.vue';
import BaseFilterable from './BaseFilterable.vue';
import BaseFilterableContainer from './BaseFilterableContainer.vue';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import { TableItemFormatter } from '@/components/reports-v2/components/elements/charts/helpers/tableItemFormatter';
import InputSwitch from 'primevue/inputswitch';
import Dialog from 'primevue/dialog';
import gdbx from '@/store/modules/gdbx';

@Component({
	components: {
		BaseFilterable,
		DataInfo,
		DataTable,
		Column,
		InputText,
		Button,
		InputSwitch,
		Dialog,
	},
})
export default class TableFilterable extends BaseFilterableContainer {
	// items & fields
	@Prop({ default: () => [] }) public items!: any[];
	@Prop({ default: () => [] }) public fields!: any[];
	// sorting
	@Prop() public sortField?: string;
	@Prop({ default: true }) public isSortable?: boolean;
	@Prop({ default: -1 }) public sortOrder?: number;
	@Prop({ default: () => [] }) public firstLevelDetailFields!: any[];
	@Prop({ default: false }) public isFixedColumn?: boolean;
	// filtering
	@Prop({}) public filters!: string[];
	// row expand
	@Prop({ default: null }) public expandMode!: 'expand' | 'dialog' | null;
	// edit
	@Prop({ default: false }) public isEditable!: boolean;
	@Prop({ default: () => [] }) public expandedFields!: any[];
	// dialog
	@Prop({ default: () => [] }) public dialogFields!: any[];
	@Prop({}) public dialogEntryKey!: string;
	@Prop({}) public expandColumnKey!: string;
	@Prop({}) public stickyHeader?: number | string;
	@Prop({}) public rowClass?:
		| ((
				item: any,
				type: 'row' | 'row-details' | 'row-top' | 'row-bottom' | 'table-busy',
		  ) => string | string[])
		| string
		| string[];
	@Prop({}) public exportFileName?: string;

	public selectedFilter: any = {
		global: { value: null, matchMode: FilterMatchMode.CONTAINS },
	};

	public expandedRows: any[] = [];
	public isDialogVisible: boolean = false;

	public dialogData = {
		title: '',
    extras: '',
		amount: '',
		items: [] as any[],
	};

    public isValidURL(string: string) {
        const regex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
        return regex.test(string);
    }

    public goTo(url: string) {
        const x = url!;
        window.open(x, '_blank');
    }

	public processHeaderName(name: string) {
		const newString = name.replaceAll('_', ' ');
		const finalString = newString
			.toLowerCase()
			.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
		return finalString;
	}

	public formatLocal(option: any, value: any) {
		if (option) {
			return option(value);
		}

		return value;
	}

	public getDialogData(title: string, items: any[], extras: string, test) {
		const newItems: any[] = [];

		items.filter((a) => a !== null).forEach((item) => {
			if (this.expandMode === 'expand' && item[this.dialogEntryKey] === title && item) {
				newItems.push(...item.items);
			}

			if (this.expandMode === 'dialog') {
				newItems.push(item);
			}
		});

		this.dialogData.title = title;
  this.dialogData.extras = extras;
		this.dialogData.items = newItems;

		function thousandFormatter(x) {
			return gdbx.currencySymbol + ' ' + Math.ceil(x).toLocaleString('en-US');
		}

  if (test.amount) {
      this.dialogData.amount = thousandFormatter(
					newItems.reduce((a, b) => {
						return a + (b ? b.os_amount : 0);
					}, 0),
			);
    }

  if (test.data) {
      this.dialogData.amount = TableItemFormatter.currency(test.data.amount);
    }

		this.openDialog();
	}

	public onCellEditComplete(event) {
		const { data, newValue, field } = event;

		if (newValue.length > 0) {
			data[field] = newValue;
		} else {
			event.preventDefault();
		}
	}

	public exportCSV() {
		if (this.$refs.dt as any) {
			(this.$refs.dt as any).exportCSV();
		}
	}

	public exportExpandCSV() {
		if (this.$refs.dtExpand as any) {
			(this.$refs.dtExpand as any).exportCSV();
		}
	}

	public openDialog() {
		this.isDialogVisible = true;
	}

	public closeDialog() {
		this.dialogData.title = '';
  this.dialogData.extras = '';
		this.dialogData.items = [];
		this.isDialogVisible = false;
	}

	public get isContainDataInfoName() {
		return this.dataInfoName ? true : false;
	}

	public get isExpandable() {
		if (this.expandMode && this.expandMode === 'expand') {
			return true;
		}

		return false;
	}
}
