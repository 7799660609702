
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import SelectButton from 'primevue/selectbutton';
import { ValueText } from '@/store/models.def';

@Component({
	components: { SelectButton },
})
export default class SwitchAmountQuantityToggle extends Vue {
	public localSwitchAmountQuantity: string = 'amount';
	public options: ValueText[] = [
		{ value: 'amount', text: 'Amount' },
		{ value: 'quantity', text: 'Quantity' },
	];

	@Watch('localSwitchAmountQuantity')
	public onLocalSwitchAmountQuantityChanged(newVal) {
		this.$emit('fromSwitchAmountQuantity', newVal);
	}
}
