
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import TableFilterable from '@/components/reports-v2/components/filterables/TableFilterable.vue';
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import {
  DimensionData,
  PermissionsGroup,
  DimensionMap,
  NumberRangeOperator,
} from '@/store/models.def';
import { TableItemFormatter } from '@/components/reports-v2/components/elements/charts/helpers/tableItemFormatter';
import SwitchAmountQuantityToggle from '@/components/SwitchAmountQuantityToggle.vue';
import SwitchCodeDescToggle from '@/components/SwitchCodeDescToggle.vue';
import moment from 'moment';

@Component({
  components: {
    TableFilterable,
    SwitchAmountQuantityToggle,
    SwitchCodeDescToggle,
  },
})
export default class StockAgingTable extends FilterWidget {
  @Prop({ default: () => [] }) public selectedLocations!: string[];

  public get permissionIds(): PermissionsGroup[] {
    return ['stocks'];
  }

  public filterIds: Array<
      | 'date'
      | 'dateAsOf'
      | 'dateRange'
      | 'stockItems'
      | 'agents'
      | 'customers'
      | 'suppliers'
      | 'locations'
      > = ['dateAsOf', 'stockItems', 'locations'];

  public switchAmountQuantity: string = 'amount';
  public switchCodeDesc: string = 'code';
  public periodMap: DimensionMap[] = [];
  public quantityAging: DimensionData[] = [];
  public amountAging: DimensionData[] = [];

  public get exportFileName() {
    const formattedDate = moment(this.selectedAsOfDate).format('DD MMM YY');
    return 'Stock Aging' + '_' + 'As Of ' + formattedDate;
  }

  public get tableFields() {
    return [
      { key: 'item', sortable: true, stickyColumn: true },
      ...this.periodMap.map((dd) => ({
        key: dd.text,
        // label: dd.text,
        sortable: true,
        formatter:
            this.switchAmountQuantity === 'amount'
                ? TableItemFormatter.currency
                : TableItemFormatter.basic,
      })),
      {
        key: 'total',
        sortable: true,
        formatter:
            this.switchAmountQuantity === 'amount'
                ? TableItemFormatter.currency
                : TableItemFormatter.basic,
      },
    ];
  }

  public get tableItems() {
    const dimensionDatas =
        this.switchAmountQuantity === 'amount'
            ? this.amountAging
            : this.quantityAging;

    return dimensionDatas
        .filter((dd) => dd.sum > 0)
        .map((dd) => {
          const item: any = {
            total: dd.sum,
            item: this.switchCodeDesc === 'code' ? dd.value[0] : dd.text,
          };
          dd.subDimension!.forEach((sd) => {
            item[sd.text] = sd.sum;
          });
          return item;
        });
  }

  public triggerSwitchAmountQuantity(value) {
    this.switchAmountQuantity = value;
  }

  public triggerSwitchCodeDesc(value) {
    this.switchCodeDesc = value;
  }

  public get dateFormatted() {
    return [this.selectedAsOfDate];
  }

  public get expensiveHook() {
    const { selectedAsOfDate, selectedStockItems, selectedLocations } = this;
    return JSON.stringify([
      selectedAsOfDate,
      selectedStockItems,
      selectedLocations,
    ]);
  }

  public async expensiveCalc() {
    let pa = 0.2;
    let pb = 0;

    const ref = FilteredDatabase.ref('stockTrans')
        .stockItems(this.selectedStockItems)
        .includes('location', this.selectedLocations)
        .dateAsOf(this.selectedAsOfDate);

    const periodMap = (this.periodMap = await this._generateDateDimensionMap(
        ref,
        'last30-120days',
        pa,
        pb,
    ));

    const stockOutRef = FilteredDatabase.ref('stockTrans')
        .numberRange('quantityOut', [
          ['>', Number.NEGATIVE_INFINITY],
          ['<', 0],
        ])
        .stockItems(this.selectedStockItems)
        .includes('location', this.selectedLocations)
        .dateAsOf(this.selectedAsOfDate);

    pb += pa;
    pa = 0.2;

    const allQuantityOuts = await this._loadDimensionByFilters(
        stockOutRef,
        'stockItems',
        pa,
        pb,
        'quantityOut',
    );

    const stockInRef = FilteredDatabase.ref('stockTrans')
        .numberRange('quantityIn', [
          ['>=', 0],
          ['<', Number.POSITIVE_INFINITY],
        ])
        .stockItems(this.selectedStockItems)
        .includes('location', this.selectedLocations)
        .dateAsOf(this.selectedAsOfDate);

    pb += pa;
    pa = 0.2;

    const allQuantityIns = await this._loadDimensionByFilters(
        stockInRef,
        'stockItems',
        pa,
        pb,
        (reff, paa, pbb) =>
            this._loadDimension(reff, periodMap, paa, pbb, 'quantityIn'),
    );

    pb += pa;
    pa = 0.2;

    const allAmountIns = await this._loadDimensionByFilters(
        stockInRef,
        'stockItems',
        pa,
        pb,
        (reff, paa, pbb) =>
            this._loadDimension(reff, periodMap, paa, pbb, 'amountIn'),
    );

    pb += pa;
    pa = 0.2;

    for (let i = 0; i < allQuantityOuts.length; i++) {
      const quantityAging = allQuantityIns[i].subDimension!;

      let stocksOut = -allQuantityOuts[i].sum;

      // which index has leftover stock
      let splitIndex = -1;
      if (stocksOut > 0) {
        for (let j = quantityAging.length - 1; j >= 0; j--) {
          const number = quantityAging[j].sum;

          if (stocksOut === number) {
            quantityAging[j].sum = 0;
            stocksOut = 0;
            break;
          } else if (stocksOut > number) {
            quantityAging[j].sum = 0;
            stocksOut -= number;
          } else if (stocksOut < number) {
            splitIndex = j;
            quantityAging[j].sum -= stocksOut;
            stocksOut = 0;
            break;
          }
        }
      }

      const amountAging = allAmountIns[i].subDimension!;

      for (let j = 0; j < amountAging.length; j++) {
        if (quantityAging[j].sum === 0) {
          amountAging[j].sum = 0;
        }
      }

      if (splitIndex !== -1) {
        const splitRef = FilteredDatabase.ref('stockTrans')
            .numberRange('quantityIn', [
              ['>=', 0],
              ['<', Number.POSITIVE_INFINITY],
            ])
            .includes('itemCode', allAmountIns[i].value as string[])
            .includes('location', this.selectedLocations)
            .numberRange(
                'date',
                allAmountIns[i].subDimension![splitIndex]
                    .value as NumberRangeOperator,
            );
        const cardsIn = await (await splitRef.get()).getCards();
        const cardsToSplit = cardsIn.sort((a, b) => a.date - b.date);

        let quantityLeft = quantityAging[splitIndex].sum;

        let amountLeft = 0;
        // for (let j = cardsToSplit.length - 1; j >= 0; j--) {
        for (const card of cardsToSplit) {
          // for (let j = 0; j < cardsToSplit.length; j++) {
          // 	const card = cardsToSplit[j];

          if (quantityLeft === 0) {
            break;
          } else if (quantityLeft >= card.quantityIn) {
            quantityLeft -= card.quantityIn;
            amountLeft += card.amountIn;
          } else if (quantityLeft < card.quantityIn) {
            amountLeft += quantityLeft * card.cost;
            quantityLeft = 0;
          }
        }
        amountAging[splitIndex].sum = amountLeft;
      }

      allQuantityIns[i].sum = quantityAging.reduce((s, sd) => sd.sum + s, 0);
      allAmountIns[i].sum = amountAging.reduce((s, sd) => sd.sum + s, 0);
      this.generateLoadingText((pa / allQuantityIns.length) * (i + 1) + pb);
    }

    this.quantityAging = allQuantityIns;
    this.amountAging = allAmountIns;
    this.saveHistory('periodMap', 'amountAging', 'quantityAging');
  }

  public generateStockItemQuantityAndAmountAging(
      index: number,
      totalQuantityOut: number,
      quantityIns: number[],
      amountIns: DimensionData[],
  ) {}
}
