
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import BasePage from '../BasePage.vue';
import moment from 'moment';

@Component({
	components: {},
})
export default class Page extends BasePage {
	@Prop({ default: () => [] }) public selectedAgents!: string[];
	@Prop({ default: () => [] }) public selectedLocations!: string[];
	@Prop({ default: () => [] }) public selectedStockItems!: string[];
	@Prop({
		default: moment()
			.startOf('day')
			.valueOf(),
	})
	public selectedAsOfDate!: number;

	@Prop({
		default: () => [
			moment()
				.startOf('day')
				.add(-1, 'year')
				.valueOf(),
			moment()
				.endOf('day')
				.valueOf(),
		],
	})
	public selectedDateRange!: [number, number];
}
