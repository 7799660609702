
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import {
	GroupedDataDic,
	GroupedSumDic,
	GroupNameSumPair,
	SortedAndOtherGrouped,
	AmountCard,
} from '@/store/models.def';
import { Dictionary } from 'vue-router/types/router';
import { addComma } from '@/util/number';
import accountx from '@/store/modules/accountx';
import moment from 'moment';

@Component({
	components: {},
})
export default class BasePage extends Vue {
	@Prop({ required: true, default: null }) public currentFocusingFilterId!:
		| string
		| null;

	public onHovering(value: boolean, filterId: string) {
		this.$emit('hovering', value, filterId);
	}
	public onEditing(value: boolean, filterId: string) {
		this.$emit('editing', value, filterId);
	}
}
