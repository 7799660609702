import gdbx from '@/store/modules/gdbx';
import { addComma } from '@/util/number';
import moment from 'moment';

export class TableItemFormatter {
	public static basic = (value: number, key?: string, item?: any) =>
		`${addComma(value, gdbx.numDecimal, true)}`

	public static basicWithDecimals = (value: number) =>
		`${value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`

	public static currency = (value: number, key?: string, item?: any) =>
		`${gdbx.currencySymbol} ${addComma(Number(value), gdbx.numDecimal, true)}`
	public static percentage = (value: number, key?: string, item?: any) =>
		`${addComma(value, gdbx.numDecimal, true)} %`
	public static percentageWithoutDecimal = (value: number, key?: string, item?: any) =>
		`${addComma(value, 0, false, false)} %`

	public static date = (value: number, key?: string, item?: any) =>
		`${moment(value).format('DD MMM YYYY')}`
	public static date2 = (value: number, key?: string, item?: any) =>
		`${moment(value).format('MMM YY')}`
	public static date3 = (value: number, key?: string, item?: any) =>
		`${moment(value).format('DD MMM YYYY')}`
	public static date4 = (value: number, key?: string, item?: any) =>
		`${moment(value).format('MMM')}`
}
