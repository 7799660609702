/**
 * Group by and nested group by functions
 */
export function nestedGroupBy(arr, properties) {
	properties = Array.from(properties);
	if (properties.length === 1) {
		return groupBy(arr, properties[0]);
	}
	const property = properties.shift();
	const grouped = groupBy(arr, property);
	for (const key in grouped) {
		if (grouped.hasOwnProperty(key)) {
			grouped[key] = nestedGroupBy(grouped[key], Array.from(properties));
		}
	}

	return grouped;
}

export function groupBy(conversions, property) {
	return conversions.reduce((acc, obj) => {
		const key = obj[property];
		if (!acc[key]) {
			acc[key] = [];
		}
		acc[key].push(obj);
		return acc;
	}, {});
}
