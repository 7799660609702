
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import Page from './Page.vue';
import userx from '@/store/modules/userx';
import router from '@/router';
import StockAvailabilityTable from '@/components/reports-v2/components/codedWidgets/stock/StockAvailabilityTable.vue';
import StockBatchExpiryTable from '@/components/reports-v2/components/codedWidgets/stock/StockBatchExpiryTable.vue';
import StockAgingTable from '@/components/reports-v2/components/codedWidgets/stock/StockAgingTable.vue';
import StockItemMonthlyPerformanceTable from '@/components/reports-v2/components/codedWidgets/stock/StockItemMonthlyPerformanceTable.vue';
import {FilteredDatabase} from '@/worker/fd/FilteredDatabase';
import ClosingStockByLocation from '@/components/reports-v2/components/codedWidgets/stock/ClosingStockByLocation.vue';

@Component({
	components: {
        ClosingStockByLocation,
    StockItemMonthlyPerformanceTable,
		StockAvailabilityTable,
    StockBatchExpiryTable,
		StockAgingTable,
	},
})
export default class Page1 extends Page {

	public mounted() {
		this.track();
	}

	public track() {
		this.$ga.set('userId', userx.user!.uid + '_' + userx.user!.email);
		this.$ga.page(router);
	}
}
