
import {Component, Prop, Watch} from 'vue-property-decorator';
import TableFilterable from '@/components/reports-v2/components/filterables/TableFilterable.vue';
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import {FilteredDatabase} from '@/worker/fd/FilteredDatabase';
import {PermissionsGroup, StockDbCard, StockTransCard} from '@/store/models.def';
import Checkbox from 'primevue/checkbox';
import moment from 'moment';
import {nestedGroupBy} from '@/util/nestedGroupBy';

@Component({
	components: {
		TableFilterable,
		Checkbox,
	},
})
export default class StockAvailabilityTable extends FilterWidget {

	public get permissionIds(): PermissionsGroup[] {
		return ['stocks'];
	}

	public get exportFileName() {
		const formattedDate = moment(this.selectedAsOfDate).format('DD MMM YY');
		return 'Stock Availability' + '_' + 'As Of ' + formattedDate;
	}

	public get dateFormatted() {
		return this.selectedAsOfDate;
	}

	public get expensiveHook() {
		const { selectedAsOfDate, selectedStockItems, selectedLocations } = this;
		return JSON.stringify([
			selectedAsOfDate,
			selectedStockItems,
			selectedLocations,
		]);
	}

  public get date() {
    return this.selectedAsOfDate;
  }

  public get newTableFields(): any [] {
    const table = [
      {
        key: 'status',
        sortable: true,
        stickyColumn: true,
      },
      {
        key: 'days',
        sortable: true,
        stickyColumn: true,
      },
      {
        key: 'expiry_date',
        sortable: true,
        stickyColumn: true,
      },
      {
        key: 'batch_number',
        sortable: true,
        stickyColumn: true,
      },
      {
        key: 'item_code',
        sortable: true,
        stickyColumn: true,
      },
      {
        key: 'item_desc',
        sortable: true,
        stickyColumn: true,
      },
      {
        key: 'qty',
        sortable: true,
        stickyColumn: true,
      },
      {
        key: 'unit_cost',
        sortable: true,
        stickyColumn: true,
      },
    ];
    return table;
  }
	@Prop({ default: () => [] }) public selectedLocations!: string[];

	public isShowReorderAdvice: boolean = false;

	public filterIds: Array<
		| 'date'
		| 'dateAsOf'
		| 'dateRange'
		| 'stockItems'
		| 'agents'
		| 'customers'
		| 'suppliers'
		| 'locations'
	> = ['dateAsOf', 'stockItems', 'locations'];

	public tableItems: any[] = [];
	public tableFields: any[] = [];
  public stockCards: any [] = [];
	public result: any[] = [];
  public initialTableItems: any [] = [];
  public afterCalculationTableItems: any [] = [];

  public dateFormatter(month, year) {
    return moment(`${year}-${month}`, 'YYYY-MM').format('x');
  }

  public thousandFormatter(x) {
    return x.toLocaleString('en-US');
  }

  public stockAvailabilityTable() {
    const nestedArray = nestedGroupBy(this.initialTableItems, ['itemCode']);

    const newArray: any [] = [];

    const todayDate = moment().valueOf();

    for (const item of Object.keys(nestedArray)) {
      if (item) {
        const eachItem = nestedArray[item][nestedArray[item].length - 1];
        let obj: any = {};

        if (this.stockCards[this.stockCards.findIndex((a) => {
          return a.itemCode === eachItem.itemCode;
        })]) {
          obj = {
            days: Math.ceil(moment.duration(eachItem.expiryDate - todayDate).asDays()),
            item_code: eachItem.itemCode,
            item_desc: this.stockCards[this.stockCards.findIndex((a) => {
              return a.itemCode === eachItem.itemCode;
            })].itemDesc,
            batch_number: eachItem.batchNo,
            expiry_date: moment(eachItem.expiryDate).format('DD/MM/YYYY'),
            unit_cost: (eachItem.avgCost).toFixed(2),
            qty: (eachItem.balanceQuantity).toFixed(2),
          };
        }


        const statusFunction = (x) => {
          if (x > 0) {
            return 'Going to expire.';
          }
          if (x < 0) {
            return 'Expired.';
          }
        };

        obj.status = statusFunction(obj.days);

        newArray.push(
            obj,
        );
      }
    }

    this.loading = false;
    this.saveHistory('afterCalculationTableItems');

    return this.afterCalculationTableItems = newArray;
  }

  public async expensiveCalc() {
    const table = await FilteredDatabase.ref('stockBatchExpiry')
        .stockItems(this.selectedStockItems)
        .includes('location', this.selectedLocations)
        .get();
    const desc = await FilteredDatabase.ref('globalStocks').get();
    this.stockCards = await desc.getCards();
    this.initialTableItems = await table.getCards();

    this.stockAvailabilityTable();
  }
}
