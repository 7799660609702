
import {Component, Prop, Watch} from 'vue-property-decorator';
import TableFilterable from '@/components/reports-v2/components/filterables/TableFilterable.vue';
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import {FilteredDatabase} from '@/worker/fd/FilteredDatabase';
import {PermissionsGroup, StockDbCard, StockTransCard} from '@/store/models.def';
import Checkbox from 'primevue/checkbox';
import moment from 'moment';
import {nestedGroupBy} from '@/util/nestedGroupBy';

@Component({
    components: {
        TableFilterable,
        Checkbox,
    },
})
export default class StockAvailabilityTable extends FilterWidget {

    public get permissionIds(): PermissionsGroup[] {
        return ['stocks'];
    }

    public get exportFileName() {
        const formattedDate = moment(this.selectedAsOfDate).format('DD MMM YY');
        return 'Stock Availability' + '_' + 'As Of ' + formattedDate;
    }

    public get dateFormatted() {
        return [this.selectedAsOfDate];
    }

    public get expensiveHook() {
        const { selectedAsOfDate, selectedStockItems, selectedLocations, isShowReorderAdvice } = this;
        return JSON.stringify([
            selectedAsOfDate,
            selectedStockItems,
            selectedLocations,
            isShowReorderAdvice,
        ]);
    }

    public get date() {
        return this.selectedAsOfDate;
    }

    public get newTableFields(): any [] {
        let table = [
            {
                key: 'UOM',
                sortable: true,
                stickyColumn: true,
            },
            {
                key: 'on-hand_qty',
                sortable: true,
                stickyColumn: true,
            },
            {
                key: 'item_code',
                sortable: true,
                stickyColumn: true,
            }, {
                key: 'item_description',
                sortable: true,
                stickyColumn: true,
            },
        ];

        if (this.isShowReorderAdvice) {
            table = [
                {
                    key: 'UOM',
                    sortable: true,
                    stickyColumn: true,
                },
                {
                    key: 'on-hand_qty',
                    sortable: true,
                    stickyColumn: true,
                },
                {
                    key: 'P_O',
                    sortable: true,
                    stickyColumn: true,
                },
                {
                    key: 'S_O',
                    sortable: true,
                    stickyColumn: true,
                },
                {
                    key: 'available',
                    sortable: true,
                    stickyColumn: true,
                },
                {
                    key: 'item_code',
                    sortable: true,
                    stickyColumn: true,
                }, {
                    key: 'item_description',
                    sortable: true,
                    stickyColumn: true,
                },
                {
                    key: 'reorder_advice',
                    sortable: true,
                    stickyColumn: true,
                },
                {
                    key: 'reorder_level',
                    sortable: true,
                    stickyColumn: true,
                },
                {
                    key: 'reorder_qty',
                    sortable: true,
                    stickyColumn: true,
                },
                {
                    key: 'unit_cost',
                    sortable: true,
                    stickyColumn: true,
                },
            ];
        }
        return table;
    }
    @Prop({ default: () => [] }) public selectedLocations!: string[];

    public isShowReorderAdvice: boolean = false;

    public filterIds: Array<
        | 'date'
        | 'dateAsOf'
        | 'dateRange'
        | 'stockItems'
        | 'agents'
        | 'customers'
        | 'suppliers'
        | 'locations'
    > = ['dateAsOf', 'stockItems', 'locations'];

    public tableItems: any[] = [];
    public tableFields: any[] = [];
    public stockCards: any [] = [];
    public result: any[] = [];
    public initialTableItems: any [] = [];
    public afterCalculationTableItems: any [] = [];

    public dateFormatter(month, year) {
        return moment(`${year}-${month}`, 'YYYY-MM').format('x');
    }

    public thousandFormatter(x: number) {
        return x ? x.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) : x;
    }

    public stockAvailabilityTable() {
        const nestedArray = nestedGroupBy(this.initialTableItems, ['itemCode']);

        const totalArray = nestedGroupBy(this.initialTableItems, ['itemCode']);

        const newArray: any [] = [];

        for (const item of Object.keys(nestedArray)) {
            const eachItem = nestedArray[item][nestedArray[item].length - 1];
            const totalQuantity = totalArray[item].reduce((a, b) => a + b.balance, 0);
            let obj: any = {};

            obj = {
                'item_code': eachItem.itemCode,
                'item_description': this.stockCards[this.stockCards.findIndex((a) => {
                    return a.itemCode === eachItem.itemCode;
                })].itemDesc,
                'on-hand_qty': nestedArray[item].length > 1 ?
                    (totalQuantity)
                    : (parseFloat(eachItem.balance)),
                'UOM': eachItem.uom,
                'reorder_level': eachItem.reorderLevel,
                'reorder_qty': eachItem.reorderQty,
                'P_O': eachItem.poAmount,
                'S_O': eachItem.soAmount,
                'unit_cost': eachItem.avgCost,
            };

            obj.quantity = obj['on-hand_qty'] + obj.P_O - obj.S_O;

            obj.reorder_advice = (obj.quantity > obj.reorder_level) ? 0 : (obj.reorder_level - obj.quantity);

            obj.available = this.thousandFormatter(obj.quantity);

            obj['on-hand_qty'] = this.thousandFormatter(obj['on-hand_qty']);

            obj.reorder_advice = this.thousandFormatter(obj.reorder_level + obj.reorder_qty - obj.available);

            obj.reorder_qty = this.thousandFormatter(obj.reorder_qty);

            if (!obj.P_O) {
                obj.P_O = null;
            } else {
                obj.P_O = '+ ' + obj.P_O;
            }

            if (!obj.S_O) {
                obj.S_O = null;
            } else {
                obj.S_O = '- ' + obj.S_O;
            }

            newArray.push(
                obj,
            );
        }

        if (this.isShowReorderAdvice) {
            this.loading = false;
            return this.afterCalculationTableItems = newArray.filter((a) => a.reorder_advice > 0);
        }

        this.loading = false;
        return this.afterCalculationTableItems = newArray;
    }

    public async expensiveCalc() {
        const table = await FilteredDatabase.ref('stockReorderAdvise')
            .stockItems(this.selectedStockItems)
            .includes('location', this.selectedLocations)
            .get();
        const desc = await FilteredDatabase.ref('globalStocks').get();
        this.stockCards = await desc.getCards();
        this.initialTableItems = await table.getCards();

        this.stockAvailabilityTable();
    }
}
