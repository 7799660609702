
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import TableFilterable from '@/components/reports-v2/components/filterables/TableFilterable.vue';
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import {
  DimensionData,
  PermissionsGroup,
  DimensionMap,
  NumberRangeOperator,
} from '@/store/models.def';
import { TableItemFormatter } from '@/components/reports-v2/components/elements/charts/helpers/tableItemFormatter';
import SwitchAmountQuantityToggle from '@/components/SwitchAmountQuantityToggle.vue';
import SwitchCodeDescToggle from '@/components/SwitchCodeDescToggle.vue';
import moment from 'moment';

@Component({
  components: {
    TableFilterable,
    SwitchAmountQuantityToggle,
    SwitchCodeDescToggle,
  },
})
export default class StockAgingTable extends FilterWidget {
  @Prop({ default: () => [] }) public selectedLocations!: string[];

  public get permissionIds(): PermissionsGroup[] {
    return ['stocks'];
  }

  public items = [
  ];

  public filterIds: Array<
      | 'date'
      | 'dateAsOf'
      | 'dateRange'
      | 'stockItems'
      | 'agents'
      | 'customers'
      | 'suppliers'
      | 'locations'
      > = ['dateAsOf', 'stockItems', 'locations'];

  public switchAmountQuantity: string = 'amount';
  public switchCodeDesc: string = 'code';
  public periodMap: DimensionMap[] = [];
  public quantityAging: DimensionData[] = [];
  public amountAging: DimensionData[] = [];

  public get exportFileName() {
    const formattedDate = moment(this.selectedAsOfDate).format('DD MMM YY');
    return 'Stock Aging' + '_' + 'As Of ' + formattedDate;
  }

  public tableItems: any [] = [];

  public tableFields: any [] = [];

  public triggerSwitchAmountQuantity(value) {
    this.switchAmountQuantity = value;
  }

  public triggerSwitchCodeDesc(value) {
    this.switchCodeDesc = value;
  }

  public get dateFormatted() {
    return [this.selectedAsOfDate];
  }

  public get expensiveHook() {
    const { selectedAsOfDate, selectedStockItems, selectedLocations } = this;
    return JSON.stringify([
      selectedAsOfDate,
      selectedStockItems,
      selectedLocations,
    ]);
  }

  public async expensiveCalc() {
    const ref = await FilteredDatabase.ref('stockTrans').get();
    const refCards = await ref.getCards();
    // get quantity in from each stock in ref and group by itemCode//
    const quantityIn = refCards.reduce((acc, card) => {
      const { itemCode, quantity } = card;
      if (acc[itemCode]) {
        acc[itemCode] += quantity;
      } else {
        acc[itemCode] = quantity;
      }
      return acc;
    }, {});
    // get total quantityOut from each stock in ref and group by itemCode//
    const quantityOutByItemCode = refCards.reduce((acc, cur) => {
      const { itemCode, quantityOut } = cur;
      if (acc[itemCode]) {
        acc[itemCode] += quantityOut;
      } else {
        acc[itemCode] = quantityOut;
      }
      return acc;
    }, {});
    // create array with objects with itemCode, stockGroup, stock in and stock out//
    this.tableItems = Object.keys(quantityIn).map((itemCode) => {
      const stock_In = quantityIn[itemCode];
      const stock_Out = quantityOutByItemCode[itemCode] || 0;
      const balance = stock_In + stock_Out;
      const stock_Group = itemCode.substring(0, 3);
      return {
        item_Code: itemCode,
        stock_Group,
        stock_In,
        stock_Out,
        balance,
      };
    });
    // create tableFields based on tableItems//
    this.tableFields = [
      { key: 'item_Code', sortable: true },
      { key: 'stock_Group', sortable: true },
      { key: 'stock_In', sortable: true },
      { key: 'stock_Out', sortable: true },
      { key: 'balance', sortable: true },
    ];
  }
}
