
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import RadioButton from 'primevue/radiobutton';

@Component({
	components: { RadioButton },
})
export default class SwitchCodeDescToggle extends Vue {
	public localSwitchCodeDesc: string = 'code';

	@Watch('localSwitchCodeDesc')
	public onLocalSwitchCodeDescChanged(newVal) {
		this.$emit('fromSwitchCodeDesc', newVal);
	}
}
