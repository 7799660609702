
import {Component} from 'vue-property-decorator';
import DataInfo from '@/components/reports-v2/components/elements/DataInfo.vue';
import Dropdown from 'primevue/dropdown';
import Button from 'primevue/button';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';
import Row from 'primevue/row';
import InputText from 'primevue/inputtext';
import BaseFilterable from '@/components/reports-v2/components/filterables/BaseFilterable.vue';
import {FilteredDatabase} from '@/worker/fd/FilteredDatabase';
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import gdbx from '@/store/modules/gdbx';
import {nestedGroupBy} from '@/util/nestedGroupBy';
import moment from 'moment';
import {FilterMatchMode} from 'primevue/api';
import {PermissionsGroup} from '@/store/models.def';

@Component({
    components: {
        DataTable,
        Column,
        ColumnGroup,
        Row,
        Dropdown,
        InputText,
        Button,
        DataInfo,
        BaseFilterable,
    },
})

export default class ClosingStockByLocation extends FilterWidget {

    public get permissionIds(): PermissionsGroup[] {
        return ['sales'];
    }

    public get exportFileName() {
        const formattedDate = moment(this.selectedAsOfDate).format('DD MMM YY');
        return 'Stock Item Budgeting ' + 'As Of ' + formattedDate;
    }

    public get dateFormatted() {
        return moment(this.selectedDateRange[0]).format('DD MMM YYYY')
        !== moment(this.selectedDateRange[1]).format('DD MMM YYYY')
            ? `${moment(this.selectedDateRange[0]).format('DD MMM YYYY')} - ${moment(
                this.selectedDateRange[1],
            ).format('DD MMM YYYY')}`
            : `As of ${moment(this.selectedDateRange[0]).format('DD MMM YYYY')}`;
    }

    public get dateFormatted2() {
        return `As of ${moment(this.selectedDateRange[1]).format('DD MMM YYYY')}`;
    }

    public get expensiveHook() {
        const {selectedAsOfDate, selectedAgents, selectedSuppliers} = this;
        return JSON.stringify([
            selectedAsOfDate,
            selectedAgents,
            selectedSuppliers,
        ]);
    }

    public get date() {
        return this.selectedDateRange;
    }

    public get dateRange() {
        return this.selectedDateRange;
    }

    public get currencySymbol() {
        return gdbx.currencySymbol + ' ';
    }

    public loading: boolean = false;

    public loadingText: string = 'Loading Data.';

    public testSet2: any [] = [];

    public tableItems: any = [];

    public expandedRows: any = [];

    public selectedFilter2: any = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS},
    };

    public selectedFilter3: any = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS},
    };

    public filterIds: Array<
        | 'date'
        | 'dateAsOf'
        | 'dateRange'
        | 'stockItems'
        | 'agents'
        | 'customers'
        | 'suppliers'
    > = ['suppliers', 'agents', 'dateAsOf'];

    public async closingStockGroups() {
        const closingStocks = FilteredDatabase.ref('closingStockBalance')
            .dateAsOf(this.selectedAsOfDate, 'postDate');
        const closingStockCards = await (await closingStocks.get()).getCards();
        const nestedInventory = nestedGroupBy((closingStockCards), ['itemCode']);

        const stockRef = FilteredDatabase.ref('globalStocks');
        const stockCards = await (await stockRef.get()).getCards();

        const modifiedArray: any [] = [];

        for (const item of Object.keys(nestedInventory)) {
            const eachItem = nestedInventory[item][nestedInventory[item].length - 1];

            modifiedArray.push({
                ...eachItem,
                utdCost: eachItem.utdCost,
                utdCostNumeral: eachItem.utdCost,
                description: stockCards[stockCards.findIndex((a) => {
                    return a.itemCode === eachItem.itemCode;
                })] ? stockCards[stockCards.findIndex((a) => {
                    return a.itemCode === eachItem.itemCode;
                })].itemDesc : '',
            });
        }


        const nestedArray = nestedGroupBy(modifiedArray, ['location']);
        const newArray: any [] = [];

        for (const item of Object.keys(nestedArray)) {

            newArray.push({
                location: item,
                groupQty:
                    nestedArray[item].reduce((a, b) =>
                        a + b.utdQty, 0,
                    ),
                groupAmt: nestedArray[item].reduce((a, b) =>
                    a + b.utdCostNumeral, 0,
                ),
                expanded: nestedArray[item].filter((a) => {
                    return a.utdQty && a.utdCost;
                }).map((items: { utdQty: any; }) => {
                    return {
                        ...items,
                        utdQty: (items.utdQty),
                    };
                }),
            });
        }

        this.testSet2 = newArray;
    }

    public expandAll() {
        this.expandedRows = this.testSet2;
    }

    public collapseAll() {
        this.expandedRows = null;
    }

    public exportFileName2(x) {
        const formattedDate = moment(this.selectedDateRange[1]).format('DD MMM YYYY');
        return x + ' ' + 'As Of ' + formattedDate;
    }

    public thousandFormatter(x) {
        return Math.ceil(x).toLocaleString('en-US');
    }

    public status(x) {
        if (x < 0) {
            return 'No Available Budget To Order';
        }
        if (x > 0) {
            return 'Available Budget To Order';

        }
        if (x === 0) {
            return 'None';
        }
    }

    public exportCSV2() {
        if (this.$refs.dt2Expand as any) {
            (this.$refs.dt2Expand as any).exportCSV();
        }
    }

    public async expensiveCalc() {
        await this.closingStockGroups();
    }
}
